<template>
  <div class="purchase">
    <img src="@/assets/images/image-page.png" class="image-page" alt="" />
    <header-page>
      <span class="text-dark fw-bold-700 size14">Tambah Pemesanan</span>
    </header-page>

    <keep-alive>
      <component :is="comp" />
    </keep-alive>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BImg,
  BPagination,
  BFormSelect,
  BModal,
  VBToggle,
  BRow,
  BCol,
} from "bootstrap-vue";

import HeaderPage from "@/components/HeaderPage.vue";
import TablePemesanan from "@/components/Purchase/TablePemesanan.vue";

export default {
  title() {
    return "Purchase";
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TablePemesanan,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      comp: "TablePemesanan",
    };
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
</style>

<style scoped>
.purchase .image-page {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
</style>

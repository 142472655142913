<template>
  <b-modal
    id="modal-purchase"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer" @click="directPage">
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              {{ editUuid == null ? "Tambah Pemesanan" : "Detail Pemesanan" }}
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
            >
              <b-spinner small v-if="isLoading" />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" sm="12" md="8">
            <b-row>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-nama-gudang">Pilih Pemasok</label>
                <b-form-group>
                  <v-select
                    id="v-nama-gudang"
                    v-model="formPayload.po_application_id"
                    label="target_merchant"
                    :options="listMerchantData"
                    placeholder="Pemasok"
                    @input="getProduct($event)"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2 custom__form--input">
                <label for="v-date-in">Tanggal </label>
                <b-form-group>
                  <b-form-input
                    id="v-date-in"
                    v-model="formPayload.date"
                    type="date"
                    class="custom__input"
                    style="padding-top: 1rem"
                    placeholder="dd/mm/yyy"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="form-purchase">
              <div class="table-responsive" style="height: 30vh">
                <table>
                  <thead>
                    <tr>
                      <th>Nama produk <span class="text-danger">*</span></th>
                      <th>Qty <span class="text-danger">*</span></th>
                      <th>Pricelist Rp <span class="text-danger">*</span></th>
                      <th>Total Diskon</th>
                      <th>Total</th>
                      <th>Nego</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in formPayload.items"
                      :id="item.id"
                      :key="item.id"
                    >
                      <td class="pl-0 pr-1 pb-25">
                        <v-select
                          v-model="formPayload.items[index].product_id"
                          label="alias_name"
                          :options="listProduct"
                          placeholder="Product"
                          :reduce="(product) => product.uuid"
                        />
                      </td>
                      <td class="pr-1 pb-25">
                        <b-form-group
                          class="custom__form--input mb-0"
                          label=""
                          label-for="quantity"
                        >
                          <input
                            :id="`qty-${index}`"
                            :value="formPayload.items[index].qty"
                            type="text"
                            class="custom__input pl-1"
                            style="width: 4rem"
                            @input="getQty(index)"
                            @change="getQty(index)"
                          />
                        </b-form-group>
                      </td>
                      <td class="pr-1 pb-25">
                        <b-form-group
                          class="custom__form--input mb-0"
                          label=""
                          label-for="cost"
                        >
                          <input
                            :id="`rupiah-${index}`"
                            :value="formPayload.items[index].price"
                            type="text"
                            class="custom__input pl-2"
                            style="width: 9rem"
                            @input="getRupiah(index)"
                            @change="getRupiah(index)"
                          />
                        </b-form-group>
                      </td>
                      <td class="pr-1 pb-25">
                        <b-form-group
                          class="custom__form--input mb-0"
                          label=""
                          label-for="disc-rp"
                        >
                          <span
                            class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                            style="
                              background: #eff1f5;
                              border: 1px solid #e4e5ec;
                              border-radius: 8px;
                              height: 52px;
                              width: 9rem;
                            "
                            >{{ totalDiscount[index] | formatAmount }}</span
                          >
                        </b-form-group>
                      </td>
                      <td class="pb-25 pr-0">
                        <span
                          class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                          style="
                            background: #eff1f5;
                            border: 1px solid #e4e5ec;
                            border-radius: 8px;
                            height: 52px;
                            width: 12rem;
                          "
                          >{{ total[index] | formatAmount }}</span
                        >
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <b-form-checkbox
                            :id="'checkbox-' + index"
                            v-model="
                              formPayload.items[index].is_need_negotiation
                            "
                            :name="'checkbox-' + index"
                            value="1"
                            unchecked-value="0"
                          />

                          <b-dropdown
                            id="dropdown-dropright"
                            class="p-0"
                            dropright
                          >
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle"
                              />
                            </template>
                            <b-dropdown-item @click="removeItem(index)">
                              <span> Hapus produk </span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </td>
                    </tr>
                    <!-- add more rows here -->
                  </tbody>
                </table>
              </div>
              <b-button
                v-if="!$route.params.id"
                class="btn-block mb-lg-4 mb-md-1"
                variant="outline-dark border-white btn__add--product"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Tambah Produk</span>
              </b-button>
            </div>
            <b-row>
              <b-col cols="12" md="3" class="custom__form--input">
                <label for="v-total-qty"
                  >Total Qty <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-total-qty"
                    v-model="formPayload.qty"
                    type="text"
                    class="custom__input"
                    style="padding-top: 1rem"
                    placeholder="Total"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" class="custom__form--input">
                <label for="v-sub-total"
                  >Sub Total <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-sub-total"
                    v-model="formPayload.qty"
                    type="text"
                    class="custom__input"
                    style="padding-top: 1rem"
                    placeholder="Total"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" class="custom__form--input">
                <label for="v-biaya-tambahan"
                  >Biaya Tambahan <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-biaya-tambahan"
                    v-model="formPayload.biaya_tambahan"
                    type="text"
                    class="custom__input"
                    style="padding-top: 1rem"
                    placeholder="Total"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3" class="custom__form--input">
                <label for="v-grand-total"
                  >Grand Total <span class="text-danger">*</span></label
                >
                <b-form-group>
                  <b-form-input
                    id="v-grand-total"
                    v-model="formPayload.grand_total"
                    type="text"
                    class="custom__input"
                    style="padding-top: 1rem"
                    placeholder="Total"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BAvatar,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
// import { VueTelInput } from "vue-tel-input";

import UploadPhoto from "@/components/UploadPhoto.vue";
import GeolocationSelectorMap from "@/components/GeolocationSelectorMap.vue";
import Maps from "@/components/Maps.vue";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    BSpinner,
    GeolocationSelectorMap,
    Maps,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormRadio,
    // VueTelInput,
  },
  props: {
    formData: {
      type: "",
    },
    createItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    result: {
      type: Object,
    },
    listMerchant: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formPayload: {
        po_application_id: null,
        booking_date: "",
        items: [
          {
            product_id: "",
            qty: 0,
            is_need_negotiation: 0,
            price: 0,
            discounts: 0,
          },
        ],
      },
      total: [0],
      count: [0],
      totalDiscount: [0],
      listMerchantData: [],
      isDeleting: false,
      photo_url: "",
      editUuid: null,
      userListData: [],
      listProduct: [],
      countryListData: [],
      searchQueryData: "",
    };
  },

  watch: {
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
    listMerchant(value) {
      this.listMerchantData = value;
    },
    // messages: {
    //   handler(value) {
    //     this.messagesChild = value;
    //   },
    //   deep: true,
    // },
    selectedLocation(value) {
      this.$emit("update:selectedLocation", value);
    },
  },
  mounted() {},
  methods: {
    getProduct(event) {
      this.$store
        .dispatch("pemesanan/getProduct", {
          uuid: event.uuid,
        })
        .then((result) => {
          this.listProduct = result.data.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          console.log(err);
        });
    },
    repeateAgain() {
      this.formPayload.items.push({
        product_id: "",
        qty: 0,
        is_need_negotiation: 0,
        price: 0,
        discounts: 0,
      });
      this.total.push(0);
      this.count.push(0);

      this.$nextTick(() => {
        if (this.$refs.row && this.$refs.row.length > 0) {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
        }
      });
    },
    removeItem(index) {
      this.formPayload.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    directPage() {
      // this.cleanUpForm();
      this.$bvModal.hide("modal-purchase");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
table {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 8px;
    border: unset;
  }

  th {
    background-color: transparent;
    color: #2b324f;
    position: sticky;
    top: 0;
  }
}

.v-select {
  width: 18rem;
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<!-- <style src="vue-tel-input/dist/vue-tel-input.css"></style> -->
